import React from "react"
import { graphql } from "gatsby"
import PostLink from "../components/post-link"
import Layout from '../components/layout'
import '../styles/global.css'
import Zoom from 'react-reveal/Fade' // Importing Zoom effect
import {Helmet} from "react-helmet";



const IndexPage = ({
  data: {
    allMediumPost: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.title) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return  <Layout>
      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Blog USI</title>
        <meta name="description" content="We have an energetic and passionate team who take a holistic and challenge-led approach to tackling issues in the places we live, work and play."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/blog" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        <link rel="apple-touch-icon" sizes="180x180" href="favicon/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="favicon/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="favicon/favicon-16x16.png"/>
        <link rel="manifest" href="favicon/site.webmanifest"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>

  <div class="bg-yellow-400 md:p-8 top">
  <div class="container w-full md:w-1/2 p-5 sm:pl-12 pb-16 pt-16 lg:pt-24">
      <h2 class="text-7xl text-white font-heading sm:leading-10">
          Blog&nbsp; 
          <br/>
      </h2>
      <p class="text-2xl text-white pt-9">Articles from a people-centered design and innovation studio tackling issues in the places we live, work and play.</p>
  </div>
</div>
<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12 m-auto px-12 py-16 lg:w-11/12 xxl:w-10/12"><Zoom>{Posts}</Zoom></div></Layout>
}

export default IndexPage

export const pageQuery = graphql`
query {
  allMediumPost(sort: { fields: [createdAt], order: DESC }) {
    edges {
      node {
        id
        title
        uniqueSlug
        virtuals {
          subtitle
          previewImage {
            imageId
          }
        }
        author {
          name
        }
      }
    }
  }
}
`
